//import i18n from '@/i18n';
import axios from 'axios';

const TICKER_INTERVAL = 1000; // milliseconds
let tickerPointer = null;

export default {
  //startTicker({ commit }) {
  startTicker(context) {
    if (tickerPointer === null) {
      tickerPointer = setInterval(() => {
        context.commit('refreshTicker');
      }, TICKER_INTERVAL);
    }
  },
  async setNowSC(context) {
    //console.log(context);
    //console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/core/date');

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    // console.log('response');
    // console.log(response);

    //TODO: Trap date fetch possible errors
    if (!response.data.success) {
      // this.showLoading = false;
      // this.displayAlert({
      //   type: 'error',
      //   title: this.$t('t-error-title'),
      //   message: this.$t('t-error-cannotfetch'),
      //   response: response
      // });
      return;
    }

    context.commit('setNowSC', response.data.result);
  },
  haltTicker({ state }) {
    clearInterval(tickerPointer);
    state.now = null;
    tickerPointer = null;
  },
  setRoute(context, payload) {
    //console.log(payload);
    if (context.state.route.to.name === 'dashboard') {
      // console.log('FROM DASHBOARD!! KILLING TIMER');
      context.dispatch(
        'websocket/deactivateStatisticsUpdates',
        {},
        {
          root: true
        }
      );
    }
    context.dispatch('websocketDashboard/sendLocation', payload.to.path, { root: true });
    context.commit('setRoute', payload);
  },
  setTable(context, payload) {
    context.commit('setTable', payload);
  },
  addTableRow(context, payload) {
    context.commit('addTableRow', payload);
  },
  setTableRows(context, payload) {
    context.commit('setTableRows', payload);
  },
  setTableTotalItems(context, payload) {
    context.commit('setTableTotalItems', payload);
  },
  setTranslator(context, payload) {
    context.commit('setTranslator', payload);
  },
  setLanguage(context, payload) {
    context.commit('setLanguage', payload);
  },
  autoSetLanguage(context) {
    const language = localStorage.getItem('sc-dashboard-language');

    if (language) {
      var newPayload = {
        language: 'fr',
        name: 'Français',
        flag: require('@/assets/images/flags/quebec.svg')
      };

      if (language === 'en') {
        newPayload = {
          language: 'en',
          name: 'English',
          flag: require('@/assets/images/flags/canada.svg')
        };
      }

      //i18n.global.locale = language;
      context.commit('setLanguage', newPayload);
    }
  }
};
