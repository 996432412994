//import router from '@/router/index.js';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  setUser(context, payload) {
    context.commit('setUser', payload.value);
  },
  // TODO: mettre des try catch partout
  async getUsers(context, payload) {
    try {
      // console.log(context);
      // console.log(payload);

      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users');

      if (typeof payload.page !== 'undefined') {
        fetchUrl.searchParams.set('_page', payload.page);
      }

      if (typeof payload.limit !== 'undefined') {
        fetchUrl.searchParams.set('_limit', payload.limit);
      }

      if (typeof payload.offset !== 'undefined') {
        fetchUrl.searchParams.set('_offset', payload.offset);
      }

      if (typeof payload.sort !== 'undefined') {
        let sortString = '';
        Object.keys(payload.sort).forEach(function (item) {
          if (payload.sort[item].active) {
            sortString += item + ':' + payload.sort[item].order + ',';
          }
        });

        if (sortString !== '') {
          fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
        }
      }

      if (typeof payload.filters !== 'undefined') {
        let filtersString = '';
        payload.filters.forEach((element) => {
          if (element.name === 'state' || element.name === 'locked' || element.name === 'deleted') {
            if (element.value === true) {
              filtersString += element.name + ':1,';
            } else {
              filtersString += element.name + ':0,';
            }
          } else {
            filtersString += element.name + ':' + element.value + ',';
          }
        });

        if (filtersString !== '') {
          fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
        } else {
          fetchUrl.searchParams.set('_filter','state:1;0,locked:1;0,deleted:1;0');
        }
      }

      // if (typeof payload.excludes !== 'undefined') {
      //   let excludesUsersRolesString = '';
      //   payload.excludes.forEach((module) => {
      //     if (typeof module['UsersRoles'] !== 'undefined') {
      //       excludesUsersRolesString += module['UsersRoles'].name + ':' + module['UsersRoles'].value + ',';
      //     }
      //   });
      //   if (excludesUsersRolesString !== '') {
      //     fetchUrl.searchParams.set('_excludeUsersRoles', excludesUsersRolesString.slice(0, -1));
      //   }
      // }

      if (typeof payload.excludes !== 'undefined') {
        let excludesString = '';
        let excludeModule = '';
        payload.excludes.forEach((module) => {
          if (typeof module['UsersRoles'] !== 'undefined') {
            excludeModule = 'UsersRoles';
            excludesString += module['UsersRoles'].name + ':' + module['UsersRoles'].value + ',';
          }
        });
        if (excludesString !== '') {
          if (excludeModule !== '') {
            if (excludeModule == 'UsersRoles') {
              fetchUrl.searchParams.set('_excludeUsersRoles', excludesString.slice(0, -1));
            }
          }
        }
      }

      // console.log(fetchUrl.href);

      const config = {
        method: 'get',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };

      const response = await axios(config);
      return response;
    } catch (err) {
      console.log(err);
    }

    // let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users');
    // fetchUrl.searchParams.set('_page', payload.page);
    // fetchUrl.searchParams.set('_limit', payload.limit);
    // fetchUrl.searchParams.set('_offset', payload.offset);

    // let sortString = '';
    // Object.keys(payload.sort).forEach(function (item) {
    //   if (payload.sort[item].active) {
    //     sortString += item + ':' + payload.sort[item].order + ',';
    //   }
    // });

    // if (sortString !== '') {
    //   fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
    // }

    // let filtersString = '';
    // payload.filters.forEach((element) => {
    //   if (element.name === 'state' || element.name === 'locked' || element.name === 'deleted') {
    //     if (element.value === true) {
    //       filtersString += element.name + ':1,';
    //     } else {
    //       filtersString += element.name + ':0,';
    //     }
    //   } else {
    //     filtersString += element.name + ':' + element.value + ',';
    //   }
    // });

    // if (filtersString !== '') {
    //   fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
    // }

    // //console.log(fetchUrl.href);

    // const config = {
    //   method: 'get',
    //   url: fetchUrl.href,
    //   headers: {
    //     //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
    //     'Content-Type': 'application/json',
    //     Authorization: 'Bearer ' + context.rootState.authentication.userToken
    //   }
    // };

    // const response = await axios(config);

    // //if (response.data.success) {
    //   // // Sets the token time to live in MS (1h)
    //   // //const tokenExpiration = 3600 * 1000;
    //   // const tokenExpiration = 3600 * 1000;
    //   // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
    //   // if (response.data.result === 'Waiting for 2FA code') {
    //   //   context.dispatch('setUserName', {
    //   //     value: payload.email
    //   //   });
    //   //   router.push({
    //   //     name: 'login-2fa',
    //   //     params: {
    //   //       email: payload.email,
    //   //       password: payload.password
    //   //     }
    //   //   });
    //   // } else {
    //   //   context.dispatch('setUser', {
    //   //     userLoggedIn: true,
    //   //     userName: payload.email,
    //   //     userDetails: response.data.result,
    //   //     userToken: response.data.token,
    //   //     userTokenExpiration: tokenExpirationDate
    //   //   });
    //   //   router.push({
    //   //     name: 'dashboard'
    //   //   });
    //   // }
    // //}
    // return response;
  },
  async getUsersForSearch(context) {
    // console.log(context);
    //console.log(payload);

    try {
      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users?_limit=all');

      const config = {
        method: 'get',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };

      const response = await axios(config);
      return response;
    } catch (err) {
      return err.response;
    }
  },
  async updateUser(context, payload) {
    //console.log(context);
    //console.log(payload);

    try {
      if (typeof payload.state !== 'undefined') {
        if (payload.state === true) {
          payload.state = 1;
        } else {
          payload.state = 0;
        }
      }

      if (typeof payload.locked !== 'undefined') {
        if (payload.locked === true) {
          payload.locked = 1;
        } else {
          payload.locked = 0;
        }
      }

      if (typeof payload.deleted !== 'undefined') {
        if (payload.deleted === true) {
          payload.deleted = 1;
        } else {
          payload.deleted = 0;
        }
      }

      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/' + payload.uuid);

      const config = {
        method: 'put',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        },
        data: payload
      };

      const response = await axios(config);

      return response;
    } catch (err) {
      return err.response;
    }
  },
  async deleteUser(context, payload) {
    try {
      const userResponse = await Swal.fire({
        title: payload.i18n('t-users-delete-areyousure'),
        text: payload.i18n('t-users-delete-confirm'),
        showCancelButton: true,
        confirmButtonColor: '#1367c8',
        confirmButtonText: payload.i18n('t-yes'),
        cancelButtonColor: '#0ab39c',
        cancelButtonText: payload.i18n('t-cancel')
      });
      if (userResponse.isConfirmed) {
        let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/' + payload.id);
        const config = {
          method: 'delete',
          url: fetchUrl.href,
          headers: {
            //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + context.rootState.authentication.userToken
          }
        };
        const response = await axios(config);
        return response;
      } else {
        return false;
      }
    } catch (err) {
      return err.response;
    }
  },
  async cloneUser(context, payload) {
    try {
      const userResponse = await Swal.fire({
        title: payload.i18n('t-users-clone-areyousure'),
        showCancelButton: true,
        confirmButtonColor: '#1367c8',
        confirmButtonText: payload.i18n('t-yes'),
        cancelButtonColor: '#0ab39c',
        cancelButtonText: payload.i18n('t-cancel')
      });
      if (userResponse.isConfirmed) {
        let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/' + payload.uuid + '/clone');
        const config = {
          method: 'post',
          url: fetchUrl.href,
          headers: {
            //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + context.rootState.authentication.userToken
          }
        };
        const response = await axios(config);
        return response;
      } else {
        return false;
      }
    } catch (err) {
      return err.response;
    }
  },
  async getUserByUuid(context, payload) {
    try {
      // console.log(context);
      //console.log(payload);

      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/' + payload.uuid);

      //console.log(fetchUrl.href);

      const config = {
        method: 'get',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };

      const response = await axios(config);

      //if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
      //}
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async getUserQrCode(context, payload) {
    try {
      // console.log(context);
      //console.log(payload);

      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/qrcode/' + payload.uuid);

      //console.log(fetchUrl.href);

      const config = {
        method: 'get',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };

      const response = await axios(config);

      //if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
      //}
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async savePassword(context, payload) {
    try {
      const config = {
        method: 'post',
        url: process.env.VUE_APP_APIURL + '/users/savepassword/' + payload.uuid,
        headers: {
          'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          password: payload.password
        })
      };

      const response = await axios(config);

      return response;
    } catch (err) {
      return err.response;
    }
  },
  async sendActivationEmail(context, payload) {
    try {
      const config = {
        method: 'post',
        url: process.env.VUE_APP_APIURL + '/users/sendactivationemail/' + payload.uuid,
        headers: {
          'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({})
      };

      const response = await axios(config);

      return response;
    } catch (err) {
      return err.response;
    }
  },
  async uploadUserLogo(context, payload) {
    //console.log(context);
    //console.log(payload.properties);

    let formData = new FormData();
    formData.append('name', payload.name);
    formData.append('folder', payload.folder);
    formData.append('asset', payload.asset);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/clouds/aws/s3/upload');

    const config = {
      method: 'post',
      url: fetchUrl.href,
      data: formData,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        //'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        //'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      },
      // transformRequest: (data, error) => {
      //   console.log(data);
      //   console.log(error);
      //   return formData;
      // },
      onUploadProgress: (progressEvent) => {
        // use upload data, since it's an upload progress
        // iOS: {"isTrusted": false, "lengthComputable": true, "loaded": 123, "total": 98902}
        //setlogoUploadPercentage(parseInt(Math.round((progressEvent.loaded/progressEvent.total) * 100)));
        //console.log(percent);
        context.commit('setlogoUploadPercentage', { value: parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)) });
      }
    };

    const response = await axios(config);

    return response;
  },
  async setEventContributorEvents(context, payload) {
    try {
      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/' + payload.uuid + '/setEvents');
      const config = {
        method: 'post',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        },
        data: JSON.stringify({
          events: payload.events
        })
      };
      const response = await axios(config);
      return response;
    } catch (err) {
      return err.response;
    }
  }
};
